import _ from 'lodash';

export default {
	namespace: true,

	state: {
		assetTypeCategories: {}
	},

	mutations: {
		SET_ALL_ASSET_TYPE_CATEGORIES(state, assetTypeCategories) {
			state.assetTypeCategories = assetTypeCategories;
		}
	},

	actions: {
		setAllAssetTypeCategories(vuexContext, assetTypeCategories) {
			vuexContext.commit('SET_ALL_ASSET_TYPE_CATEGORIES', assetTypeCategories);
		}
	},

	getters: {
		assetTypeCategories(state) {
			if (!_.isEmpty(state.assetTypeCategories)) {
				return state.assetTypeCategories;
			}
			return {};
		},
	}
}
