import _ from 'lodash';

export default {
	namespace: true,

	state: {
		disposals: {},
		currDisposal: {},
	},

	mutations: {
		SET_DISPOSALS(state, disposals) {
			state.disposals = disposals;
		},
		SET_CURR_ITEM_DISPOSAL(state, currDisposal) {
			state.currDisposal = currDisposal;
		},
	},

	actions: {
		setAllDisposals(vuexContext, disposals) {
			vuexContext.commit('SET_DISPOSALS', disposals);
		},
		updateAllDisposals(vuexContext, newDisposals) {
			let disposals = vuexContext.getters.disposals;

			_.forEach(newDisposals, (value, key) => {
				disposals[key] = value;
			});

			vuexContext.commit('SET_DISPOSALS', disposals);
		},
	},

	getters: {
		disposals(state) {
			if (!_.isEmpty(state.disposals)) {
				return state.disposals;
			}
			return {};
		},
		currDisposal(state) {
			if (!_.isEmpty(state.currDisposal)) {
				return state.currDisposal;
			}
			return {};
		},
	},
};
