export default {
	apiKey: "AIzaSyDJ_aNsG8XSDX7DYiqiV6r7tqqmycQPnaM",
	authDomain: "ares-preprod-app.firebaseapp.com",
	databaseURL: "https://ares-preprod-app.firebaseio.com",
	projectId: "ares-preprod-app",
	storageBucket: "ares-preprod-app.appspot.com",
	messagingSenderId: "955681832561",
	appId: "1:955681832561:web:4874d7eeeecd7e6bc965d0",
	measurementId: "G-YQLXB335WT"
};
