import _ from 'lodash';

export default {
	namespace: true,

	state: {
		assetTypes: {},
		connectedAssetTypes: {},
		currAssetType: {},
	},

	mutations: {
		SET_ALL_ASSET_TYPES(state, assetTypes) {
			state.assetTypes = assetTypes
		},
		SET_COMPANY_ASSET_TYPES(state, assetTypes) {
			state.connectedAssetTypes = assetTypes;
		},
		SET_CURR_ASSET_TYPE(state, assetType) {
			state.currAssetType = assetType
		},
	},

	actions: {
		setAllAssetTypes(vuexContext, assetTypes) {
			vuexContext.commit('SET_ALL_ASSET_TYPES', assetTypes);
		},
		setAllConnectedAssetTypes(vuexContext, assetTypes) {
			vuexContext.commit('SET_CONNECTED_ASSET_TYPES', assetTypes);
		},
		updateAllAssetTypes(vuexContext, newAssetTypes) {
			let assetTypes = vuexContext.getters.assetTypes;

			_.forEach(newAssetTypes, (value, key) => {
				assetTypes[key] = value;
			});

			vuexContext.commit('SET_ALL_ASSET_TYPES', assetTypes);
		},
	},

	getters: {
		assetTypes(state) {
			if (!_.isEmpty(state.assetTypes)) {
				return state.assetTypes;
			}
			return {};
		},
		connectedAssetTypes(state) {
			if (!_.isEmpty(state.connectedAssetTypes)) {
				return state.connectedAssetTypes;
			}
			return {};
		},
		currAssetType(state) {
			if (!_.isEmpty(state.currAssetType)) {
				return state.currAssetType;
			}
			return {};
		},
	}
}
