import _ from 'lodash';

export default {
	namespace: true,

	state: {
		assets: {},
		currAsset: {}
	},

	mutations: {
		SET_ALL_ASSETS(state, assets) {
			state.assets = assets;
		},
		SET_CURR_ASSET(state, asset) {
			state.currAsset = asset;
		}
	},

	actions: {
		setAllAssets(vuexContext, assets) {
			vuexContext.commit('SET_ALL_ASSETS', assets);
		},
		updateAllAssets(vuexContext, newAssets) {
			let assets = vuexContext.getters.assets;

			_.forEach(newAssets, (value, key) => {
				assets[key] = value;
			});

			vuexContext.commit('SET_ALL_ASSETS', assets);
		}
	},

	getters: {
		assets(state) {
			if (!_.isEmpty(state.assets)) {
				return state.assets;
			}
			return {};
		},
		currAsset(state) {
			if (!_.isEmpty(state.currAsset)) {
				return state.currAsset;
			}
			return {};
		},
	}
};
