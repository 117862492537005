import _ from 'lodash';

export default {
    namespace: true,

    state: {
        companyAssetPools: {},
        currCompanyAssetPool: {},
    },

    mutations: {
        SET_ALL_COMPANY_ASSET_POOLS(state, companyAssetPools) {
            state.companyAssetPools = companyAssetPools
        },
        SET_CURR_COMPANY_ASSET_POOL(state, currCompanyAssetPool) {
            state.currCompanyAssetPool = currCompanyAssetPool
        }
    },

    actions: {
        setAllCompanyAssetPools(vuexContext, companyAssetPools) {
            vuexContext.commit('SET_ALL_COMPANY_ASSET_POOLS', companyAssetPools);
        },
        updateAllCompanyAssetPools(vuexContext, newCompanyAssetPools) {
            let companyAssetPools = vuexContext.getters.companyAssetPools;

            _.forEach(newCompanyAssetPools, (value, key) => {
                companyAssetPools[key] = value;
            });

            vuexContext.commit('SET_ALL_COMPANY_ASSET_POOLS', companyAssetPools);
        },
    },

    getters: {
        companyAssetPools(state) {
            if (!_.isEmpty(state.companyAssetPools)) {
                return state.companyAssetPools;
            }
            return {};
        },
        currCompanyAssetPool(state) {
            if (!_.isEmpty(state.currCompanyAssetPool)) {
                return state.currCompanyAssetPool;
            }
            return {};
        },
    }
}