import _ from 'lodash';

export default {
    namespace: true,

    state: {
        assetIssuances: {},
        currAssetIssuance: {},
    },

    mutations: {
        SET_ASSET_ISSUANCES(state, assetIssuances) {
            state.assetIssuances = assetIssuances
        },
        SET_CURR_ASSET_ISSUANCE(state, currAssetIssuance) {
            state.currAssetIssuance = currAssetIssuance
        }
    },

    actions: {
        setAllAssetIssuances(vuexContext, assetIssuances) {
            vuexContext.commit('SET_ASSET_ISSUANCES', assetIssuances);
        },
        updateAllAssetIssuances(vuexContext, newAssetIssuances) {
            let assetIssuances = vuexContext.getters.assetIssuances;

            _.forEach(newAssetIssuances, (value, key) => {
                assetIssuances[key] = value;
            });

            vuexContext.commit('SET_ASSET_ISSUANCES', assetIssuances);
        },
    },

    getters: {
        assetIssuances(state) {
            if (!_.isEmpty(state.assetIssuances)) {
                return state.assetIssuances;
            }
            return {};
        },
        currAssetIssuance(state) {
            if (!_.isEmpty(state.currAssetIssuance)) {
                return state.currAssetIssuance;
            }
            return {};
        },
    }
}