import _ from 'lodash';

export default {
	namespace: true,

	state: {
		storageLocations: {},
		connectedStorageLocations: {},
		currStorageLocation: {},
	},

	mutations: {
		SET_ALL_STORAGE_LOCATIONS(state, storageLocations) {
			state.storageLocations = storageLocations
		},
		SET_CONNECTED_STORAGE_LOCATIONS(state, storageLocations) {
			state.connectedStorageLocations = storageLocations;
		},
		SET_CURR_STORAGE_LOCATION(state, storageLocation) {
			state.currStorageLocation = storageLocation
		},
	},

	actions: {
		setAllStorageLocations(vuexContext, storageLocations) {
			vuexContext.commit('SET_ALL_STORAGE_LOCATIONS', storageLocations);
		},
		setAllConnectedStorageLocations(vuexContext, storageLocations) {
			vuexContext.commit('SET_CONNECTED_STORAGE_LOCATIONS', storageLocations);
		},
		updateAllStorageLocations(vuexContext, newStorageLocations) {
			let storageLocations = vuexContext.getters.storageLocations;

			_.forEach(newStorageLocations, (value, key) => {
				storageLocations[key] = value;
			});

			vuexContext.commit('SET_ALL_STORAGE_LOCATIONS', storageLocations);
		},
	},

	getters: {
		storageLocations(state) {
			if (!_.isEmpty(state.storageLocations)) {
				return state.storageLocations;
			}
			return {};
		},
		connectedStorageLocations(state) {
			if (!_.isEmpty(state.connectedStorageLocations)) {
				return state.connectedStorageLocations;
			}
			return {};
		},
		currStorageLocation(state) {
			if (!_.isEmpty(state.currStorageLocation)) {
				return state.currStorageLocation;
			}
			return {};
		}
	}
}
