const VueGeolocation = {
    install(Vue) {
        Vue.prototype.$getCurrentLocation = VueGeolocation.getCurrentLocation;
        Vue.prototype.$geocoder = VueGeolocation.geocoder;
    },
    getCurrentLocation() {
        const options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0 // To avoid storing position in cache
        }

        return new Promise((resolve, reject) => {
            if (!VueGeolocation._isAvailable()) {
                reject(new Error('Geolocation is not available'));
            } else {
                window.navigator.geolocation.getCurrentPosition(function () { }, function () { }, {});
                window.navigator.geolocation.getCurrentPosition(
                    position => {
                        resolve({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        })
                    },
                    () => {
                        reject(new Error('No position access'));
                    },
                    options
                )
            }
        })
    },
    geocoder(location) {
        return new Promise((resolve, reject) => {
            if (location === null) {
                reject(new Error('Location must not be null'));
            }

            // eslint-disable-next-line no-undef
            const geocoder = new google.maps.Geocoder;
            geocoder.geocode({ location }, (results, status) => {
                if (status === 'OK') {
                    if (results[1]) {
                        resolve(results[1]);
                    }
                } else {
                    reject(status);
                }
            });
        });
    },
    _isAvailable() {
        return 'geolocation' in window.navigator
    }
}

export default VueGeolocation;